<template>
	<div class="list">
		<img src="../../assets/img/xintp/banner.jpg" alt="" style="width:100%;height:auto">
		<!-- <div class="subbar-wrapper">
      <label for="tab-spring" :class="{'active' : active==6}" class="tab__label tab__label--spring typesquare_option"
        @click="nato(6);">机构简介</label>
      <label for="tab-summer" :class="{'active' : active==8}" class="tab__label tab__label--summer typesquare_option"
        @click="nato(8);">大事要闻</label>
      <label for="tab-autumn" :class="{'active' : active==9}" class="tab__label tab__label--autumn typesquare_option"
        @click="nato(9);">通知公告</label>
      <label for="tab-winter" :class="{'active' : active==7}" class="tab__label tab__label--winter typesquare_option"
        @click="nato(7);">数据播报</label>
    </div> -->
		<router-view @getLoad="showMsg" />
	</div>
</template>

<script>
export default {

	data() {
		return {
			active: this.$route.params.newsid,

		}
	},
	methods: {
		showMsg() {
			var that = this
			that.$emit('getLoad', true);
		},
		nato(e) {
			var that = this
			that.active = e
			if (e == 6) {
				that.$router.push('/bggk/ggjj/' + e)
			}
			if (e == 9 || e == 8) {
				that.$router.push('/bggk/listGk/' + e)
			}
			if (e == 7) {
				that.$router.push('/bggk/sjbb/' + e)
			}
		}
	}
}
</script>

<style></style>
